import AppHost from 'components/AppHost';
import useSession from 'components/useSession';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect } from 'react';

const isDev = process.env.NODE_ENV === 'development';

const Content: FunctionComponent = (props) => {
  const router = useRouter();
  const [session] = useSession();

  useEffect(() => {
    if (session.tenant.isAdmin && isDev === false) {
      router.replace('/admin/tenants');
    } else {
      router.replace('/screening');
    }
  }, [session.tenant]);

  return null;
};

const IndexPage: FunctionComponent = () => {
  return (
    <AppHost>
      <Content />
    </AppHost>
  );
};

export default IndexPage;
