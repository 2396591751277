import useSession, { Session } from 'components/useSession';
import ErrorPage from 'next/error';
import { FunctionComponent, useEffect } from 'react';

const AppHost: FunctionComponent = (props) => {
  const [session, dispatch] = useSession();

  useEffect(() => {
    fetch('/api/host')
      .then((res) => res.json())
      .then((data: Omit<Session, 'user'>) => {
        dispatch([
          { type: 'tenant_set', tenant: data.tenant },
          { type: 'firebase_set', firebase: data.firebase },
          { type: 'hostInit_set', hostInit: true },
        ]);
      });
  }, []);

  if (session.hostInit === false) return null;

  return session.tenant ? (
    <>{props.children}</>
  ) : (
    <ErrorPage statusCode={404} />
  );
};

export default AppHost;
